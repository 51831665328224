import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Spacer from "../components/spacer"
import useForm from "../hooks/form"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => {

	const { submit,submitted, unfilledRequired } = useForm()

	var contact = data.allDatoCmsContactPage.nodes[0];
	return (
		<Layout className='with-background'>
			<SEO title="Contact Us" />
			<div className="p40 flex m-wrap   ">
				<div className="w-50 m-100">
					<h2 className="m0 mt40 m-m0 max-550" dangerouslySetInnerHTML={{ '__html': contact.contactTitle }}></h2>
					<p className="m0 max-350 m-mt40 mt80">{contact.contactText}</p>
				</div>
				<div className="w-50 m-100">
					<Spacer className='m-hide' />
					<p className="m0 small-cap mt60 small ">Enquiry</p>
					<div className="pos-rel mt40 ">
						<form className={" flex flex-wrap contact--form   " + (submitted ? 'submitted' : '')} name="contact" method="POST" data-netlify="true">

							<input type="hidden" name="form-name" value="contact" />

							<div className="w-50 flex">
								<input required name='firstName' placeholder="First Name*" className={"input w-100 mr10" + (unfilledRequired['firstName']  ?' unfilled' : '')}/>
							</div>
							<div className="w-50 flex">
								<input required name='lastName' placeholder="Last Name*" className={"input ml10 w-100 " + (unfilledRequired['lastName']  ?' unfilled' : '')} />
							</div>
							<div className="w-50 flex">
								<input required name='Phone' placeholder="Phone Number*" className={"input mr10 w-100" + (unfilledRequired['Phone']  ?' unfilled' : '')} />
							</div>
							<div className="w-50 flex">
								<input name='Email' placeholder='Email*' className={"input ml10 w-100" + (unfilledRequired['Email']  ?' unfilled' : '')} />
							</div>
							<div className="w-100 mb20">
								<input className={"w-100 input" + (unfilledRequired['comment']  ?' unfilled' : '')} placeholder="Comment*" required name='comment' />
							</div>
							<button className="read--more form-button left-0" onClick={submit}><span></span> Submit</button>
						</form>

						<div className={"contact--form--submitted pos-abs top-0  " + (submitted ? 'submitted' : '')}>
							<h1 className="h3">Thanks for the enquiry.
								We will get back to you shortly.</h1>
						</div>
						
					</div>
					<Spacer />
					
				
				</div>
			
			</div>
			<div className="p40 pt0 pb0  ">
			<div data-scroll className="line--in w100 "  />
			</div>
		
			
		</Layout>
	)

}

export default ContactPage

export const query = graphql`
query ContactQuery {
  allDatoCmsContactPage {
    nodes {
      contactTitle
      contactText
    }
  }
}
`